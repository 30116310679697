import React, { useContext, useState, useRef} from 'react';
import './Expoactiva.css';
import { SalmekContext } from '../../Context/Context';
import styled from "styled-components";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Imagen } from "../UI/Estilos";


const ImageContainer = styled.div`
  width: 80%;
  height: 100%;
  padding-right: 10px;
  padding: 5px;
  margin-left: auto;
  margin-rigth: auto;
  position: relative;
  margin-left: 10%;
  box-sizing: border-box;
  padding-bottom: 30px;
  margin-bottom: -80px;
`;


const ModalContainer = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;


const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Img = styled(Imagen)`
  box-sizing: border-box;
  height: auto;
  object-fit: cover;
  border: 1px black;
      border: 1px solid ${({color}) => color };
    box-sizing: content-box;
    width: 75%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    &&:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
    @media screen and (max-width:800px){
    width: 8rem;
    padding: 5px;
  }
`;

  const H2 = styled.h2`
  text-align: left;
  `;


const images = [
  { 
      id: "1",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2Finauguracion.jpg?alt=media&token=bc620349-a6db-4525-a5b7-b662546eb804", 
      alt: 'Imagen 1' 
  },
  { 
      id: "2",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2FsergioyLacallePou.jpg?alt=media&token=7bc3b6e7-0210-4a57-a3bd-62b2e307d8d1", 
      alt: 'Imagen2' 
  },
  { 
      id: "3",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2Fplano.jpg?alt=media&token=824e6b19-e0b3-41f7-aeba-704f4fd88c9e", 
      alt: 'Imagen 3' 
  },
  { 
      id: "4",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2FImagen%20de%20WhatsApp%202024-03-19%20a%20las%2021.26.41_f41b431c.jpg?alt=media&token=a7721163-1964-42b8-a847-c29cdf561686", 
      alt: 'Imagen 4' 
  },
  { 
      id: "5",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2FImagen%20de%20WhatsApp%202024-03-19%20a%20las%2021.26.41_43534638.jpg?alt=media&token=3413a857-c874-4638-8e41-f9131109b9ba", alt: 'Imagen 5' },
  { 
      id: "6",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2FImagen%20de%20WhatsApp%202024-03-19%20a%20las%2021.26.42_f748814d.jpg?alt=media&token=28662c4e-a795-4d42-a788-396c01868448", alt: 'Imagen6' },
  { 
      id: "7",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2FImagen%20de%20WhatsApp%202024-03-19%20a%20las%2021.26.43_0f13fbf4.jpg?alt=media&token=a43b47d1-667f-426e-878b-606b1df7e93d", alt: 'Imagen 7' },
  { 
      id: "8",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2FIMG-20240319-WA0036.jpg?alt=media&token=5e5d285c-a268-48ec-8e1d-42ab0bb9008b", alt: 'Imagen 8' },
  { 
      id: "9",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2FIMG-20240319-WA0040.jpg?alt=media&token=64601ebf-38f4-4761-a1ed-de354fb537ad", alt: 'Imagen 9' },
  { 
      id: "10",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2Fexpofrancisco.jpg?alt=media&token=cf438fac-0ecf-4a62-8229-d951fa9271a2", alt: 'Imagen 10' },
  { 
      id:"11",
      src: "https://firebasestorage.googleapis.com/v0/b/reactapp-683e2.appspot.com/o/images%2Fexpotolva.jpg?alt=media&token=264d8ddd-0c05-4966-b5d9-4179993f89a9", alt: 'Imagen 11' }
];


function Image({ src, alt, onClick }) {
  return <Img src={src} alt={alt} onClick={onClick} />;
}

function Expo() {
  const [expandedImage, setExpandedImage] = useState(null);
  const { theme } = useContext(SalmekContext);
  const swiperRef = useRef(null);


  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
    color: theme === 'dark' ? '#fff' : '#343a40',
  };


  const openExpandedImage = (image) => {
    setExpandedImage(image);
  };

  const closeExpandedImage = () => {
    setExpandedImage(null);
  };


  return (
    <div style={containerStyles}>
      <ImageContainer>
        <H2>Fotos Expoactiva 2024</H2><br/>
      <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination, Scrollbar, Autoplay]}
          spaceBetween={1}
          slidesPerView={2}
          effect='fade'
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
          breakpoints={{
            1600: {
              slidesPerView: 8
            },
            1024: {
              slidesPerView: 6
            },
            640: {
              slidesPerView: 4,
            },
            480: {
              slidesPerView: 3
            }
          }}
          navigation
        >
                {images.map((image, index) => (
                   <SwiperSlide key={index} style={containerStyles}>
                  <Image
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    onClick={() => openExpandedImage(image.src)}
                  />
                 </SwiperSlide>
          ))}
        </Swiper>
      </ImageContainer>


      <ModalContainer isOpen={expandedImage}>
        <ModalContent onClick={closeExpandedImage}>
          <img src={expandedImage} alt="Expanded" className="expanded-image" />
        </ModalContent>
      </ModalContainer>
      
    </div>
  );
};

export default Expo;



