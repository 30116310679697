import styles from "./Slider.module.css";
import useScrollReveal from "../../Core/useScrollReveal.js";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import Maicero from "../../assets/img/MaiceroMDD200.png";
import Mixer from "../../assets/img/mixer.jpg";
import Tolvas from "../../assets/img/tolvas.jpg";

const data = [
  {
  id: "1", 
  image: Maicero, 
  title: "Maicero", 
  link: "/detail/L6c2sadcLsCrTQQ6Njlj"
  },
  {
  id: "2", 
  image: Mixer, 
  title: "Mixer", 
  link: "/category/Preparación-Distribución"

  },
  {
  id: "3",
  image: Tolvas,
  title: "Tolvas",
  link: "/detail/FzPSg5f1MpEBWGoh67uO"

  }
];

function Slider() {
  const { useReveal } = useScrollReveal();
  useReveal(styles.slidercontainer);

  return (
    <div id="Slider" className={styles.slidercontainer}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation
        autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
      >
        {data.map(item => (
          <SwiperSlide key={item.id} >
            <div className={styles.sliderimage}>
              <img src={item.image} alt={item.title} />
              <div className={styles.boxblack}></div>
              <div className={styles.slidetext}>
                <Link to={item.link} className={styles.sliderbutton}>
                  <button id="titulo">Ver {item.title}</button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Slider;
