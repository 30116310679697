import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { SalmekContext } from "../../Context/Context";
import "./NavBarDropdown.css";

const A = styled.a`
  text-decoration: none;
`;

const Ul = styled.ul`
  display: inline-flex;
`;

export default function NavBarDropdown() {
  const categories = [
    "Cosecha", 
    "Almacenamiento", 
    "Preparación-Distribución", 
    "Henificacion", 
    "Silaje", 
    "Repuestos", 
    "Manuales", 
    "Ofertas"
  ];
  const { t } = useTranslation();
  const { theme } = useContext(SalmekContext);
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#ED1C24',
    color: theme === 'dark' ? '#fff' : 'white',
  };

  return (
    <Ul className="navbar-nav me-auto mb-2 mb-lg-0" style={containerStyles}>
      <li 
        className="nav-item dropdown"
        onMouseEnter={() => setIsDropdownOpen(true)}
        onMouseLeave={() => setIsDropdownOpen(false)}
      >
        <A 
          className="nav-link dropdown-toggle" 
          href="#" 
          data-bs-toggle="dropdown" 
          style={containerStyles}
        >
          {t("categories")}
        </A>
        {isDropdownOpen && (
          <ul className="dropdown-menu">
            {categories.map((element) => (
              <li key={element}>
                <Link className="dropdown-item" to={`/category/${element}`}>
                  {element}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    </Ul>
  );
}
