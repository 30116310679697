import VideoBanner from "../components/VideoBanner";
import Banner from "../components/Banner/Banner";
import Location from "../components/Location/Location";
import Tarjetas from "../assets/img/TARJETAS.jpg"
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AudioBanner from "../components/audioSlider";
import UsadosGarantizados from "../components/Usados";
import Expo from "../components/Expoactiva";
import ContactForm from "../components/ContactForm/ContactForm";
import { useContext } from "react";
import { SalmekContext } from "../Context/Context";
import Slider from "../components/Slider";
import Coments from "../components/Coments";
// import CompanyMembers from "../../pages/Servicio";

const TC = styled.img`
  width: 96%;
  height: 80% ;
  display: flex;
  margin-left: 5px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: -30px;
`;
const Title1 = styled.h1`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: -0.5px;
  margin-top: -4px;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  font-family: 'Baloo 2', sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
      @media only screen and (max-width: 800px){
      font-size: 2rem;
      }
`;
const Title2 = styled.h2`
  text-align: center;
  margin-top: -5px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 30px;
  font-family: Poppins;

      // @media only screen and (max-width: 800px){
      // font-size: 1rem;
      // }
`;

const Title3 = styled.h1`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
  text-align: center;
  font-family: 'Baloo 2', sans-serif;
  font-weight: 800;
  font-size: 3rem;
      @media only screen and (max-width: 800px){
      font-size: 1rem;
      }
`;

const Section = styled.section`
background-color: #343a40;

`;
const Div = styled.div`
 margin-bottom: -10px;
`;

export default function Home() {
  const { t } = useTranslation();
  const { theme } = useContext(SalmekContext);

  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
    color: theme === 'dark' ? '#fff' : '#343a40',
  };

  return (
    <>
      <Slider/>
      <Div style={containerStyles}>
        <Title1>{t("greeting")}</Title1>
        <Title2>{t("greeting2")}</Title2>
      </Div>
      <div className="container-fluid pt-2 px-3" style={containerStyles}>

        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-12 p-1">
              <Banner category="Cosecha" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 p-1">
              <Banner category="Almacenamiento" />
            </div>
            <div className="col-12 col-sm-12 col-lg-6 p-1">
              <Banner category="Silaje" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-12 p-1">
              <Banner category="Preparación-Distribución" />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 p-1">
              <Banner category="Henificacion" />
            </div>
            <div className="col-12 col-sm-12 col-lg-6 p-1">
              <Banner category="Repuestos" />
            </div>
          </div>
        </div>
      </div>
      <UsadosGarantizados />
      <VideoBanner />
      <AudioBanner />
      <Expo/>

      {/* <CompanyMembers /> */}
      <Section style={containerStyles}>

      <Coments />
      <Title3>
        <b>{t("location")}</b>
      </Title3>
        <hr></hr>
      <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="row" >
            <div className="col-sm-0 col-md-1 col-lg-1 p-3">
            </div>

            <div className="col-sm-12 col-md-11 col-lg-5 p-3">
                <Location />
            </div>
            <div className="col-sm-12 col-md-11 col-lg-5 p-3">
                <ContactForm />
            </div>

            <div className="col-sm-0 col-md-1 col-lg-1 p-3">
            </div>
            </div>
          </div>

      </Section>
      <TC src={Tarjetas} alt="Medios de Pago de Salmek International" />
    </>
  );
}
