import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { CardVideo } from "../CardVideo";
import { SalmekContext } from "../../Context/Context";
import { videos} from "../../Contents/index";

const VideoContainer = styled.div`
  width: 80%;
  height: 100%;
  padding-right: 10px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-left: 10%;
  box-sizing: border-box;
`;

  const Title = styled.h1`
  width: 100%;
  text-align: center;
  padding-top: 45px;
  font-family: 'Baloo 2', sans-serif;
  font-weight: 800;
  font-size: 3rem;
    @media only screen and (max-width: 800px){
      font-size: 2rem;
      }
  `;

  const Span = styled.span`
    display: flex;
  `;

  const H2 = styled.h2`
    text-align: left;
  `;
  

const VideoBanner = () => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const { theme } = useContext(SalmekContext);

  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
    color: theme === 'dark' ? '#fff' : '#343a40',
  }; 

  return (
    <div style={containerStyles}>
      <Title>
        <b>{t("multimedia")}</b>
      </Title>
      <hr></hr>

      <VideoContainer>
        <H2>Entrevistas y Videos Institucionales</H2><br/>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination, Scrollbar, Autoplay]}
          spaceBetween={1}
          slidesPerView={2}
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
          effect='fade'
          breakpoints={{
            1600: {
              slidesPerView: 8
            },
            1024: {
              slidesPerView: 6
            },
            640: {
              slidesPerView: 4,
            },
            480: {
              slidesPerView: 3
            }
          }}
          navigation
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index}  style={containerStyles}>
              <CardVideo link={video.videoUrl} src={video.coverImage} />
              <Span>{video.titulo}</Span>
            </SwiperSlide>
          ))}
        </Swiper>
        </VideoContainer>
    </div>
  );  
};

export default VideoBanner;
