import styled from "styled-components";

const Table=styled.table`
  width: 100%;
  border: 1px solid #ddd;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  @media screen and (max-width:1000px){
  }
`;
const TH=styled.th`
    border: 1px solid #ddd;
`;
const TD=styled.td`
    border-left: 1px solid #ddd;
`;
const Girasolero1040 = () => {
    return (
      <Table>
        <thead>
          <tr>
            <TH colSpan="5">525 mm</TH>
            <TH colSpan="7">700 mm</TH>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TD>13</TD>
            <TD>14</TD>
            <TD>16</TD>
            <TD>19</TD>
            <TD>21</TD>
            <TD>7</TD>
            <TD>8</TD>
            <TD>9</TD>
            <TD>10</TD>
            <TD>12</TD>
            <TD>14</TD>
            <TD>16</TD>
                     </tr>
         
        </tbody>
      </Table>
    );
  };
  
  export default Girasolero1040;
  