import styled from "styled-components";

const Table=styled.table`
  width: 100%;
  border: 1px solid #ddd;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  @media screen and (max-width:1000px){
  }
`;
const TH=styled.th`
    border: 1px solid #ddd;
`;
const TD=styled.td`
    border-left: 1px solid #ddd;
`;

const MaiceroMDD200 = () => {
    return (
      <Table>
        <thead>
          <tr>
            <TH colSpan="5">525 mm</TH>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TD>14</TD>
            <TD>16</TD>
            <TD>18</TD>
            <TD>20</TD>
            <TD>22</TD>
          </tr>
        </tbody>
      </Table>
    );
  };
  
  export default MaiceroMDD200;
  