import { useContext, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { SalmekContext } from "../../Context/Context";
import CartContainer from "./CartContainer/CartContainer";
import "./CartWidget.css";
import { FaCartArrowDown} from "react-icons/fa";
import styled from "styled-components";

const Button = styled.button`
    width: 2.5rem;
    heigth: 2rem;
    padding-left: 9px;
      @media screen and (max-width:1000px){
}   
`;

export default function CartWidget(){
    const {getTotalQuantity} = useContext(SalmekContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { theme } = useContext(SalmekContext);

    const containerStyles = {
        backgroundColor: theme === 'dark' ? '#343a40'  : '#ED1C24',
        color: theme === 'dark' ? '#fff' : 'white',
  };
    return(
        <div >

            <Button  style={containerStyles} onClick={handleShow} title="Carrito" className="ms-2 btn  rounded-pill position-relative">
                <FaCartArrowDown />
                {getTotalQuantity() > 0 && <span style={containerStyles} className="cart-amount" id="cantCarrito">{getTotalQuantity()}</span>}
            </Button>

            <Offcanvas style={containerStyles}show={show} onHide={handleClose} placement="end" className="offcanvas-cart">
                <Offcanvas.Header style={containerStyles} closeButton className="offcanvas-cart-bg">
                    <Offcanvas.Title style={containerStyles}><h4 className="my-1">Carrito</h4></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-cart-bg" style={containerStyles}>
                    {getTotalQuantity() > 0 ?
                        <CartContainer handleClose={handleClose} style={containerStyles}/>
                    :
                        <p className="mx-4" style={containerStyles}>El carrito está vacío.</p>
                    }
                </Offcanvas.Body>
            </Offcanvas>

        </div>
    );
}