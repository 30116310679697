import React, { useContext, useRef } from "react";
import styled from "styled-components";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { SalmekContext } from "../../Context/Context";
import { audios } from "../../Contents/index";

const AudioContainer = styled.div`
  width: 80%;
  height: 100%;
  padding-right: 10px;
  padding: 5px;
  margin-left: auto;
  margin-rigth: auto;
  position: relative;
  margin-left: 10%;
  box-sizing: border-box;
`;

const Audio = styled.audio`
  width: 9rem;
  margin-top: 15px;
  @media screen and (max-width:1000px){
    width: 8rem;
  }
  @media screen and (max-width:800px){
    width: 7rem;
  }
  @media screen and (max-width:500px){
    width: 6.5rem;
  }
`;

const AudioCover = styled.img`
  height: auto;
  object-fit: cover;
  border: 1px solid ${({color}) => color };
  box-sizing: content-box;
  width: 75%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  @media screen and (max-width:800px){
    width: 8rem;
    padding: 5px;
  }
 `;
  const H2 = styled.h2`
  text-align: left;
  `;

const AudioBanner = () => {
  const swiperRef = useRef(null);
  const { theme } = useContext(SalmekContext);

  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
    color: theme === 'dark' ? '#fff' : '#343a40',
  };


  return (
    <div style={containerStyles}>
      <AudioContainer>
        <H2>Entrevistas en Radio Rural</H2><br/>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination, Scrollbar, Autoplay]}
          spaceBetween={1}
          slidesPerView={2}
          effect='fade'
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
          breakpoints={{
            1600: {
              slidesPerView: 8
            },
            1024: {
              slidesPerView: 6
            },
            640: {
              slidesPerView: 4,
            },
            480: {
              slidesPerView: 3
            }
          }}
          navigation
        >
          {audios.map((audio, index) => (
            <SwiperSlide key={index} style={containerStyles}>
              <AudioCover src={audio.coverImage} alt={`Audio ${index}`} />
              <Audio controls style={containerStyles}>
                <source src={audio.audioUrl} type="audio/mp3" />
                Your browser does not support the audio element.
              </Audio>
            </SwiperSlide>
          ))}
        </Swiper>
      </AudioContainer>
    </div>
  );
};

export default AudioBanner;
