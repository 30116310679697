import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ItemListContainer from './components/ItemListContainer/ItemListContainer';
import ItemDetailContainer from './components/ItemDetailContainer/ItemDetailContainer';
import CheckoutContainer from './components/CheckoutContainer/CheckoutContainer';
import OrderContainer from './components/OrderContainer/OrderContainer';
import NotFound from './pages/ErrorPage';
import { Loginpage } from './components/Login/Loginpage';
import Show from './components/Admin/Show';
import RequireAuth from './components/Login/RequireAuth';
import Edit from './components/Admin/Edit';
import Create from './components/Admin/Create';
import { Foot } from './components/Footer';
import ForgotPassword from './components/Forgot';
// import CompanyMembers from './pages/Equipo';
import Home from './pages/Home';
import Header from './components/Header';
import NavScrollExample from './components/NavBar/NavBar';
import Registerpage from './components/Register/Registerpage';

function App(props) {
  return (
    <>
      <ToastContainer autoClose={2000} hideProgressBar />
      <BrowserRouter>
        <Header />
        <NavScrollExample />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:categoryid" element={<ItemListContainer />} />
          <Route path="/search/:searchid" element={<ItemListContainer />} />
          <Route path="/detail/:id" element={<ItemDetailContainer />} />
          <Route path="/checkout" element={<CheckoutContainer />} />
          <Route path="/order/:orderid" element={<OrderContainer />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/login" element={<Loginpage />} />
          <Route path="/register" element={<Registerpage />} />
          <Route path="/show" element={<RequireAuth requiredRole="admin"><Show /></RequireAuth>} />
          {/* <Route path="/miembros" element={<CompanyMembers />} /> */}
          <Route path="/create" element={<RequireAuth requiredRole="admin"><Create /></RequireAuth>} />
          <Route path="/edit/:id" element={<RequireAuth requiredRole="admin"><Edit /></RequireAuth>} />
        </Routes>
      </BrowserRouter>
      <Foot />
    </>
  );
}

export default App;
