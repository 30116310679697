import "./Card.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from '../../Context/Context';  // Ajusta el camino de importación según tu estructura de carpetas
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const B = styled.b`
  color: red;
`;
const ModalContainer = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 999;
  top: 0;
  margin-top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

   .expanded-image {
    max-width: 95%;
    max-height: 95%;
    cursor: pointer;
  }
`;

const MachineTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 20px;
  max-height: 60px;
  font-size: small;
  text-align: center;
  // overflow-x: hidden;
  // overflow-y: scroll;
  font-weight: bold;

  @media only screen and (max-width: 1000px) {
  }
`;


const MachineDetail = styled.h5`
  margin-top: 10px;
  font-size: small;
  max-height: 60px;
  // overflow-x: hidden;
  // overflow-y: scroll;
  @media only screen and (max-width: 1500px) {
  }
  @media only screen and (max-width: 800px) {
  }
`;
const MachinePrice = styled.h5`
  margin-top: 10px;
  font-size: small;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  @media only screen and (max-width: 1500px) {
  }
  @media only screen and (max-width: 800px) {
  }
`;

const FichaTecnicaButton = styled(Link)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  text-decoration: none;
`;

const MachineContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: transparent;
`;

const MachineCard = styled.div`
  position: relative;
  max-width: 250px;
  margin-inline-end: 10px;
  height: 400px;
  padding-left: 15px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &&:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }
  @media only screen and (max-width: 800px) {
    font-size: medium;
  }
`;

function Card(props) {
  const { id, detail, imgUrls, name, price, category, pdf } = props.product;
  const { currentUser } = useAuth();
  const [expandedImage, setExpandedImage] = useState(null);
  const navigate = useNavigate();

  const handleViewManual = () => {
    if (currentUser) {
      window.open(pdf, "_blank");
    } else {
      navigate('/login');
    }
  };
  const openExpandedImage = (image) => {
    setExpandedImage(image);
  };

  const closeExpandedImage = () => {
    setExpandedImage(null);
  };

  return (
   <div>
      <MachineContainer>
        <MachineCard>
          <Carousel
            showArrows={true}
            autoPlay={true}
            showThumbs={false}
            infiniteLoop={true}
          >
            {imgUrls.map((url, index) => (
              <div key={index} onClick={() => openExpandedImage(url)}>
                <img className="tarjeta-img" src={url} alt={`${name} ${index +1}`} />
              </div>
            ))}
          </Carousel>
          <MachineTitle>{name}</MachineTitle>
          <MachineDetail>{detail}</MachineDetail>
        {category !== "Manuales" ? (
          <MachinePrice>
            {price === "0" ? (
              <p>CONSULTE POR PRECIO</p>
            ) : price === "SIN STOCK" ? (
              <B>NO DISPONIBLE</B>
            ) : (
              <b> PRECIO: USD {price.toLocaleString()}</b>
            )}
          </MachinePrice>
        ) : ""}
        
        {category !== "Manuales" ? (
          <FichaTecnicaButton to={`/detail/${id}`}className="btn btn-outline-primary">Ver más</FichaTecnicaButton>
        ) : (
          <button 
            className="btn btn-outline-primary position-absolute bottom-0 end-0 m-4" 
            onClick={handleViewManual}>
            Ver Manual
          </button>
        )}
      </MachineCard>
      </MachineContainer>
      <ModalContainer isOpen={expandedImage}>
        <ModalContent onClick={closeExpandedImage}>
          <img src={expandedImage} alt="Expanded" className="expanded-image" />
        </ModalContent>
      </ModalContainer>
    </div>
  );
}

export default Card;
