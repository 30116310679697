import { Imagen } from "../UI/Estilos";
import styled from "styled-components";

const CardVideoLink = styled.a`
`;

const CarrucelImagen = styled(Imagen)`
    border: 1px solid ${({color}) => color };
    box-sizing: content-box;
    width: 75%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    &&:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
          @media screen and (max-width:800px){
    width: 8rem;
    padding: 5px;
  }
`;

export function CardVideo({link, src, color}) {
    return (
        <CardVideoLink href={link} target="__blank" >
            <CarrucelImagen src={src} color={color} ></CarrucelImagen>
        </CardVideoLink>
    );    
}