const commentsbox = [
    {
    id: "1",
    nome: "Darwin Antúnez",
    comentario: "Buena atención.",
    img: "/img/comments/1.webp"
    },
    {
    id: "2",
    nome: "Javier Santucho",
    comentario: "Excelente atención!!",
    img: "/img/comments/2.webp"
    },
    {
    id: "3",
    nome: "Roque Lemaire",
    comentario: "Lugar impecable.",
    img: "/img/comments/3.webp"
    },
    {
    id: "4",
    nome: "B.A.A.M Cartucho",
    comentario: "Buen servicio, el mecánico es entendido y bien dispuesto para solucionar los problemas.",
    img: "/img/comments/4.webp"
    },
    {
    id: "5",
    nome: "Maicol Luzardo",
    comentario: "Sin comentarios.",
    img: "/img/comments/5.webp"
    },
    {
    id: "6",
    nome: "Cacho Pino",
    comentario: "Sin comentarios.",
    img: "/img/comments/6.webp"
    }
    
];
const videos = [
    {
        id: "1",    
        videoUrl: "https://www.youtube.com/embed/6HBAs65qP58?si=-bcb5zf-Q-x9KspS",
        coverImage: "/img/cover4.jpg",
        titulo:"Promo Precosecha"
    },
    {
        id: "2",    
        videoUrl: "https://www.youtube.com/embed/yGQ-VJFmf_Y?si=_Nmgw4XUUFlttbwi",
        coverImage: "/img/cover1.jpg",
        titulo:"ExpoPrado 2024"
    },
    {
        id: "3",    
        videoUrl: "https://www.youtube.com/embed/ATOpdfmLZYA?si=H5EAIL_m18PlMNuH",
        coverImage: "/img/cover9.jpg",
        titulo:"Salud del Suelo"
    },
    {
        id: "4",    
        videoUrl: "https://www.youtube.com/embed/uQ1dBmxElCs?si=_PEZuQ0cLCHuYkkI",
        coverImage: "/img/cover0.jpg",
        titulo:"Uruguay Productivo"
    },
    {
        id: "5", 
        videoUrl: "https://www.youtube.com/embed/R-axElaApQI?si=Tnb0gZhtpUsUmLTN",
        coverImage: "/img/cover1.jpg",
        titulo:"ExpoPrado 2023"

    },
    {
        id: "6", 
        videoUrl: "https://www.youtube.com/embed/XnrpTrzPNzM?si=5lCqcPUfaInGRimn",
        coverImage: "/img/cover2.jpg",
        titulo:"Presentación MDD200"

    }, {
        id: "7", 
        videoUrl: "https://www.youtube.com/embed/tpSCYo_3S1w?si=wJ4PTjMABJfKtfBx",
        coverImage: "/img/cover3.jpg",
        titulo:"ExpoActiva 2022"

    }, {
        id: "8", 
        videoUrl: "https://www.youtube.com/embed/03ba3o9IhlU?si=47Tzq5DRPmsyFD5t",
        coverImage: "/img/cover4.jpg",
        titulo:"Expectativas del agro"

    }, 
    {
        id: "9", 
        videoUrl: "https://www.youtube.com/embed/AN6nqcMODwo?si=dZURbJSlGK8UZfbB",
        coverImage: "/img/cover7.jpg",
        titulo:"Institucional Mainero"

    },
    {
        id: "10", 
        videoUrl: "https://www.youtube.com/embed/WlPNKs08GB4?si=CUgjivvnRWO_Z-tX",
        coverImage: "/img/cover8.jpg",
        titulo:"Entrevista con Brescia"

    }
  ];

const audios = [
        {
        id: "1",
        audioUrl: "/audio/Sergio.mp3",
        coverImage: "/img/cover100124.jpg",
        },
        {
        id: "2",
        audioUrl: "/audio/Sergio2.mp3",
        coverImage: "/img/cover061223.jpg"
        },
        {
        id: "3",
        audioUrl: "/audio/Sergio3.mp3",
        coverImage: "/img/cover160323.jpg"
        },
        {
        id: "4",
        audioUrl: "/audio/Sergio4.mp3",
        coverImage: "/img/cover300621.jpg"
        },
        {
        id: "5",
        audioUrl: "/audio/Sergio5.mp3",
        coverImage: "/img/cover220521.jpg"
        },
        {
        id: "6",
        audioUrl: "/audio/Sergio6.mp3",
        coverImage: "/img/cover200324.jpg",
        },
  ];
module.exports = {commentsbox, videos, audios};
