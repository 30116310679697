import styles from "./Header.module.css";
import { LiaMapMarkerAltSolid } from 'react-icons/lia';
import { BsFillTelephoneOutboundFill, BsWhatsapp } from 'react-icons/bs';
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialFacebookCircular} from "react-icons/ti";
import {BiLogoLinkedin} from "react-icons/bi";
import styled from "styled-components";
import { useContext } from "react";
import { SalmekContext } from "../../Context/Context.js";

const Div = styled.div`
width: 100%;
height: 60px;
font-family: 'Baloo 2', sans-serif;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
padding-top: 1rem;
margin-bottom: -10px;
font-size: 18px;
& ul{
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    & li{
        border-radius: 100%;
        width: 40px;
        height: 40px;
        list-style: none;
        background-color: var(--title-color);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        transition: ease-in-out 0.5s;
            &:hover{
                opacity: 1;
                transition: ease-in-out 0.5s;
            }
            & a{
                display: flex;
                justify-content: center;
                color: var(--primary-color);
                font-size: 25px;
                @media screen and (max-width: 1000px) {
                font-size: 1rem;}
            }
            @media screen and (max-width: 1000px) {
            width: 30px;
            height: 30px;}
    }
    @media screen and (max-width: 1000px) {
    padding-left: 1rem;
    gap:5px}
}
    @media screen and (max-width: 1000px) {
        font-size: 0.7rem;
`;

function Header ({children}){
    const { theme,  } = useContext(SalmekContext);
    const containerStyles = {
        backgroundColor: theme === 'dark' ? '#343a40'  : '#ED1C24',
        color: theme === 'dark' ? '#fff' : 'white',
  };

    return (
        <>
        <Div id="topper" style={containerStyles}>
            <div className={styles.loc}>
                <LiaMapMarkerAltSolid /> Ruta 2 Km 277,8 - Mercedes
            </div>
            <div className={styles.contact}>
                <BsFillTelephoneOutboundFill /> (+598) 45330110
                <BsWhatsapp /> (+598) 099743194
            </div>
            <ul style={containerStyles}>
                <li><a href="https://www.instagram.com/mainero_uruguay" title="Instagram" target="_blank" rel="noreferrer">
                    <SlSocialInstagram />
                </a></li>
                <li><a href="https://www.facebook.com/mainero.uy/" title="Facebook" target="_blank" rel="noreferrer"><TiSocialFacebookCircular /></a></li>
                <li><a href="https://www.linkedin.com/in/mainerouruguay" title="Linkedin" target="_blank" rel="noreferrer"><BiLogoLinkedin /></a></li>
 
            </ul>
        </Div>
        </>
    );
}

export default Header;
