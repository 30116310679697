import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { SalmekContext } from "../../Context/Context";

const Form = styled.form`
    width: 90%;
    height: 32px;
    display:flex;
    margin-left: 20px;
    padding-right: 5px;
    @media screen and (max-width: 1000px) {
        width: 55%;
        height: 30px;
      } 
    @media screen and (max-width: 600px) {
        width: 40%;
        margin-left: -2px;
      }  

`;

export default function NavBarSearch(){
    const [userInput, setUserInput] = useState("");
    const navigateTo = useNavigate();
    const {t} = useTranslation();
    const { theme } = useContext(SalmekContext);

    const containerStyles = {
        backgroundColor: theme === 'dark' ? '#343a40'  : '#ED1C24',
        color: theme === 'dark' ? '#fff' : 'white',
  };
    function handleChange(evt){
        setUserInput(evt.target.value);
    }

    function handleSubmit(evt){
        evt.preventDefault();
        navigateTo(`/search/${userInput}`);
    }

    return(
        <Form className="opacity-70" role="search" onSubmit={handleSubmit} style={containerStyles}>
            <input 
                className="form-control rounded-pill" 
                style={{textAlign:"left"}}
                type="search" 
                name="userInput" 
                value={userInput} 
                placeholder={t("search")} 
                onChange={handleChange}/>
        </Form>
    );
}