import { Link } from "react-router-dom";
import MaineroLogo from "../../assets/img/logoMainero.png";
import { Logo } from "../UI/Estilos";

export default function NavBarLogo(){
    return(
          <Link to="/">
                <Logo src={MaineroLogo} width="auto" height="auto" alt="Mainero Uruguay" /> 
          </Link>
    );
}