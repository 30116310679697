import { useAuth } from "../../Context/Context";
import { useContext, useEffect, useState } from "react";
import NavBarDropdown from "./NavBarDropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavBarLogo from "./NavBarLogo";
import { useTranslation } from "react-i18next";
import "./Navbar.css";
import user from "../../assets/img/user.png";
import { FaMoon, FaSun } from "react-icons/fa";
import CartWidget from "./CartWidget";
import { SalmekContext } from "../../Context/Context";
import NavBarSearch from "./NavBarSearch";
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function NavScrollExample() {
  const { logout, currentUser } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const { theme, toggleTheme } = useContext(SalmekContext);
  const { t } = useTranslation();

  const containerStyles = {
    backgroundColor: theme === "dark" ? "#343a40" : "#ED1C24",
    color: theme === "dark" ? "#fff" : "white",
  };
  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };

    fetchUserRole();
  }, [currentUser]);

  return (
    <Navbar expand="lg" id="barraGeneral" style={containerStyles}>
      <Container style={containerStyles}>
        <Nav.Link href="/">
          <NavBarLogo />
        </Nav.Link>
        <NavBarSearch />
        <Navbar.Toggle aria-controls="navbarScroll" style={containerStyles} />
        <Navbar.Collapse id="navbarScroll" style={containerStyles}>
          <Nav id="barra" style={containerStyles}>
            <Nav.Link href="/" className="inicio" style={containerStyles}>
              {t("home")}
            </Nav.Link>
            <NavBarDropdown/>
            <span style={containerStyles} className="carro">
              <CartWidget />
            </span>
            <li
              style={containerStyles}
              onClick={toggleTheme}
              className="tema"
            >
              {theme === "dark" ? <FaSun /> : <FaMoon />}
            </li>
            {currentUser && userRole === 'admin' && (
              <Nav.Link href="/show">
                <img src={user} alt="user" id="imagen_user" />
              </Nav.Link>
            )}
            {!currentUser && (
              <Nav.Link
                className="ingresar"
                href="/login"
                style={containerStyles}
              >
                ENTRAR
              </Nav.Link>
            )}
            {currentUser && (
              <Nav.Link
                href="/"
                name="logout"
                style={containerStyles}
                id="ingresar"
                onClick={async (e) => {
                  await logout();
                }}
              >
                SALIR
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample; 