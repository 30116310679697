import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';


export default function BreadCrumb({ currentPage }) {

    switch (currentPage) { // fallthrough switch
        case 'All':
            return (
                <div className='container pt-4 ps-5 pb-1'>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Nav.Link to="/">Inicio</Nav.Link></li>
                        <li className="breadcrumb-item active">Productos</li>
                    </ol>
                </div>
            )
        case 'Cosecha':
        case 'Almacenamiento':
        case 'Mixer':
        case 'Henificacion':
        case 'Silaje':
        case 'Repuestos':
        case 'Manuales':
        case 'Ofertas':
            return (
                <div className='container pt-4 ps-4 pb-1'>
                    <ol className="breadcrumb" >
                        <li className="breadcrumb-item" ><Nav.Link to="/">Inicio</Nav.Link></li>
                        <li className="breadcrumb-item" ><Link to="/category/All">Productos</Link></li>
                        <li className="breadcrumb-item active" >{currentPage}</li>
                    </ol>
                </div>
            )
        case 'Checkout':
        case 'Orden':
        default:
            return (
                <div className='container pt-4 ps-5 pb-1' >
                    <ol className="breadcrumb" >
                        <li className="breadcrumb-item" ><Nav.Link to="/" >Inicio</Nav.Link></li>
                        <li className="breadcrumb-item active">{currentPage}</li>
                    </ol>
                </div>
            )
    }
}
