import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import db, { storage, DeleteFile } from "../../firebase/firebase";
import "./Edit.css";

function Edit() {
    const [codigo, setCodigo] = useState("");
    const [category, setCategory] = useState("");
    const [detail, setDetail] = useState("");
    const [extra1, setExtra1] = useState("");
    const [extra2, setExtra2] = useState("");
    const [extra3, setExtra3] = useState("");
    const [extra4, setExtra4] = useState("");
    const [extra5, setExtra5] = useState("");
    const [extra6, setExtra6] = useState("");
    const [imgUrls, setImgUrls] = useState([]);
    const [imgUrlPdf, setImgUrlPdf] = useState("");
    const [pdf, setPdf] = useState("");
    const [location, setLocation] = useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [stock, setStock] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();
    const [videoUrl, setVideoUrl] = useState("");
    const [videoUrl2, setVideoUrl2] = useState("");

    useEffect(() => {
        const getProductById = async () => {
            const product = await getDoc(doc(db, "products", id));
            if (product.exists()) {
                const data = product.data();
                setCodigo(data.codigo);
                setCategory(data.category);
                setDetail(data.detail);
                setExtra1(data.extra1);
                setExtra2(data.extra2);
                setExtra3(data.extra3);
                setExtra4(data.extra4);
                setExtra5(data.extra5);
                setExtra6(data.extra6);
                setImgUrls(data.imgUrls || []);
                setImgUrlPdf(data.imgUrlPdf);
                setPdf(data.pdf);
                setLocation(data.location);
                setName(data.name);
                setPrice(data.price);
                setStock(data.stock);
                setVideoUrl(data.videoUrl);
                setVideoUrl2(data.videoUrl2);
            } else {
                console.log("El producto no existe");
            }
        }
        getProductById();
    }, [id]);

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            null,
            (error) => {
                console.error('Error uploading file:', error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setImgUrls((prevUrls) => [...prevUrls, downloadURL]);
                });
            }
        );
    };

    const handleUploadPdf = async (e) => {
        const file = e.target.files[0];
        const storageRef = ref(storage, `pdfs/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', 
            null,
            (error) => {
                console.error('Error uploading PDF:', error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setPdf(downloadURL);
                });
            }
        );
    };

    const handleDeleteFile = async (fileURL) => {
        try {
            const fileRef = ref(storage, fileURL);
            await DeleteFile(fileRef);

            setImgUrls((prevUrls) => prevUrls.filter((url) => url !== fileURL));
            if (fileURL === imgUrlPdf) {
                setImgUrlPdf("");
            }
            if (fileURL === pdf) {
                setPdf("");
            }
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    const updateProduct = async (e) => {
        e.preventDefault();
        const product = doc(db, "products", id);
        const data = {
            codigo, category, detail, extra1, extra2, extra3, extra4, extra5, extra6, imgUrls,
            imgUrlPdf, pdf, location, name, price, stock: Number(stock), videoUrl, videoUrl2
        };
        await updateDoc(product, data);
        navigate("/show");
    };

    return (
        <div className="container_edit">
            <div className="row">
                <div className="col">
                    <h1 id="edit_titulo">Editar Aviso</h1>
                    <form onSubmit={updateProduct}>
                        {/* Resto del formulario */}
                        <div className="mb-3">
                            <label className="form-label">Código</label>
                            <input
                                type="text"
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Categoría</label>
                            <select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="form-select"
                            >
                                <option value="">Seleccionar categoría</option>
                                <option value="Cosecha">Cosecha</option>
                                <option value="Almacenamiento">Almacenamiento</option>
                                <option value="Silaje">Silaje</option>
                                <option value="Preparación-Distribución">Preparación-Distribución</option>
                                <option value="Henificacion">Henificacion</option>
                                <option value="Repuestos">Repuestos</option>
                                <option value="Manuales">Manuales</option>
                                <option value="Ofertas">Ofertas</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Detalle</label>
                            <input
                                type="text"
                                value={detail}
                                onChange={(e) => setDetail(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Extra 1</label>
                            <input
                                type="text"
                                value={extra1}
                                onChange={(e) => setExtra1(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Extra 2</label>
                            <input
                                type="text"
                                value={extra2}
                                onChange={(e) => setExtra2(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Extra 3</label>
                            <input
                                type="text"
                                value={extra3}
                                onChange={(e) => setExtra3(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Extra 4</label>
                            <input
                                type="text"
                                value={extra4}
                                onChange={(e) => setExtra4(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Extra 5</label>
                            <input
                                type="text"
                                value={extra5}
                                onChange={(e) => setExtra5(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Extra 6</label>
                            <input
                                type="text"
                                value={extra6}
                                onChange={(e) => setExtra6(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Imágenes</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleUpload}
                                className="form-control"
                            />
                            {imgUrls.map((url, index) => (
                                <div key={index}>
                                    <img className="Preview" src={url} alt={`Preview ${index +1}`} />
                                    <button onClick={() => handleDeleteFile(url)}>Eliminar Imagen</button>
                                </div>
                            ))}
                             {imgUrls.length < 3 && (
                <div className="mb-3">
                  <label className="form-label">Subir Imagen Adicional</label>
                  <input
                    type="file"
                    accept="image/*;capture=camera"
                    onChange={handleUpload}
                    className="form-control"
                  />
                </div>
              )}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Pdf</label>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={handleUploadPdf}
                                className="form-control"
                            />
                            {pdf && (
                                <div>
                                    <a href={pdf} target="_blank" rel="noopener noreferrer">Ver PDF</a>
                                    <button onClick={() => handleDeleteFile(pdf)}>Eliminar PDF</button>
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Localización</label>
                            <input
                                type="text"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio</label>
                            <input
                                type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Stock</label>
                            <input
                                type="number"
                                value={stock}
                                onChange={(e) => setStock(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Url de Video</label>
                            <input
                                type="text"
                                value={videoUrl}
                                onChange={(e) => setVideoUrl(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Url de Video 2</label>
                            <input
                                type="text"
                                value={videoUrl2}
                                onChange={(e) => setVideoUrl2(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <button type="submit" className="btn btn-primary" id="boton_edit">Actualizar</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Edit;
