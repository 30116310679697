import React, { useContext, useState } from 'react'
import { SalmekContext } from '../../Context/Context';
import { Button } from 'react-bootstrap';


export default function ItemCount({ stock = 0, onAddToCart }) {
    const [count, setCount] = useState(1);
    const { theme } = useContext(SalmekContext);

    const containerStyles = {
      backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
      color: theme === 'dark' ? '#fff' : '#343a40',
    };


    function HandleAdd(event) {
        if (count < stock) {
            setCount(count + 1);
        }
    }

    function HandleSubstract(event) {
        if (count > 1) {
            setCount(count - 1);
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-stretch mt-4" style={containerStyles}>
                <div className="btn-group" style={containerStyles}>
                    <Button className="btn" type="button" onClick={HandleSubstract} disabled={count <= 1} style={containerStyles}>-</Button>
                    <p className="fs-5 px-3 py-1 my-auto" >{count}</p>
                    <Button className="btn" type="button" onClick={HandleAdd} disabled={count >= stock} style={containerStyles}>+</Button>
                </div>
            </div><br/>
            <div>
                <button className="btn btn-primary border border-dark m-2" onClick={() => onAddToCart(count)} >Agregar a la consulta</button>
            </div>
        </>
    )
}
