import { useContext } from "react";
import styled from "styled-components";
import { SalmekContext } from "../../Context/Context";
import { Link } from "react-router-dom";
import "./Location.css"

const Iframe = styled.iframe`
margin-top: 20px;
width: 90%;
height: 22rem;
display: flex;
margin-left: auto;
margin-right: auto;

@media screen and (max-width:1000px){
  /* width: 25rem; */
  height: 25rem ;
}
@media screen and (max-width:600px){
  width: 100%;
  height: 20rem ;
}
`;
const Title = styled.h4`
margin-left: 30px;
margin-right: auto;
margin-bottom: -10px;
text-align: left;
@media screen and (max-width:600px){
  margin-left: 5px;

}
`;

const P = styled.p`
  font-size: medium;
  padding-top: 5px;
`;


function Location() {
  const iframe = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3336.3274413672857!2d-58.019315985281956!3d-33.25791899504188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a53e1af32be553%3A0x571b6020629d8423!2sMainero%20Uruguay%20-%20Salmek%20International%20SA!5e0!3m2!1ses-419!2sar!4v1675688532136!5m2!1ses-419!2sar';
  const { theme } = useContext(SalmekContext);

  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
    color: theme === 'dark' ? '#fff' : '#343a40',
  };

  return (
    <>
      <section className="container-fluid pt-2 px-3" style={containerStyles}>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="row">
     
              <Title style={containerStyles}>
                  <b>Estamos en Mercedes - Soriano</b>
                  <P style={containerStyles}>
                    Ruta 2 Km 277,800 - <Link id="telefono" href="tel:+59845330110"><span  style={containerStyles}>Tel: 4533-0110 </span></Link></P>
                </Title>
                <Iframe
                  src={iframe}
                  title="Ubicacion de Salmek"
                >
                  
                </Iframe>
            </div>
            </div>
        <br /><br />
      </section>
    </>
  );
}

export default Location;
