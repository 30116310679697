import styled from "styled-components";


const Table=styled.table`
  width: 100%;
  border: 1px solid #ddd;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  @media screen and (max-width:1000px){
  }
`;
const TH=styled.th`
    border: 1px solid #ddd;
`;
const TD=styled.td`
    border-left: 1px solid #ddd;
`;
const Maicero3010a420 = () => {
    return (
      <Table>
        <thead>
          <tr>
            <TH colSpan="4">525 mm</TH>
            <TH colSpan="4">700 mm</TH>
            <TH colSpan="4">420 mm</TH>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>12</td>
            <td>14</td>
            <td>16</td>
            <td>18</td>
             <TD></TD>
            <td>10</td>
            <td>12</td>
            <td>14</td>
            <TD></TD>
            <td>20</td>
            <td>22</td>
            <td>24</td>
          </tr>
         
        </tbody>
      </Table>
    );
  };
  
  export default Maicero3010a420;
  